import { NgModule } from '@angular/core';
import { ActivationEnd, ActivationStart, Router, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '../components/home/home.component';
import { LoginComponent } from '../components/login/login.component';

import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../guards/auth.guard';
import { SettingsComponent } from '../components/settings/settings.component';
import { RunDataDumpComponent } from '../components/runDataDump/runDataDump.component';
import { InternationalReportComponent } from '../components/reports/internationalReport/internationalreport.component';
import { AppService } from '../services/app.service';
import { DateTimeFormatService } from '../services/datetime-format.service';
import { CenterSpecificDevicesComponent } from '../components/settings/centerSpecificDevices/centerSpecificDevices.component';
import { AllRunsComponent } from '../components/allRuns/allRuns.component';
import { RunPatientDeletionComponent } from '../components/runPatientDeletion/runPatientDeletion.component';
import { ScanDeviceComponent } from '../components/scanDevice/scanDevice.component';
import { PatientsWithSandDevicesComponent } from '../components/patientsWithSandDevices/patientsWithSandDevices.component';
import { XMLImportComponent } from '../components/xmlImport/xmlImport.component';
import { XMLImportTestComponent } from '../components/xmlImport/xmlImportTest.component';
import { LoginSecretComponent } from '../components/login/loginSecret.component';
import { TestDPComponent } from '../components/testDP/testDP.component';
import { CustomPatientReportComponent } from '../components/reports/customPatientReport/customPatientReport.component';
import { LoSReportComponent } from '../components/reports/losReport/losReport.component';
//import { AppGuard } from '../utility/app.guard';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'allruns', component: AllRunsComponent, canActivate: [AuthGuard] },
  { path: 'runpatientdeletion', component: RunPatientDeletionComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'rundatadump', component: RunDataDumpComponent, canActivate: [AuthGuard] },
  { path: 'centerspecificdevices', component: CenterSpecificDevicesComponent, canActivate: [AuthGuard] },
  { path: 'scandevice', component: ScanDeviceComponent, canActivate: [AuthGuard] },
  { path: 'xmlimportlive', component: XMLImportComponent, canActivate: [AuthGuard] },
  { path: 'xmlimporttest', component: XMLImportTestComponent, canActivate: [AuthGuard] },
  { path: 'patientsanddevices', component: PatientsWithSandDevicesComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'sloginE0E52F31B39847C3A8C', component: LoginSecretComponent },
  { path: 'testDatePicker', component: TestDPComponent/*, canActivate: [AppGuard]*/ },

  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'run', loadChildren: '../customModules/run.module#RunModule', canActivate: [AuthGuard] },//lazy load run module and its components
  { path: 'patient', loadChildren: '../customModules/patient.module#PatientModule', canActivate: [AuthGuard] },//lazy load patient module and its components
  { path: 'run/addenda', loadChildren: '../customModules/addenda.module#AddendaModule', canActivate: [AuthGuard] },//lazy load addenda module and its components



  //{ path: 'report', loadChildren: '../customModules/report.module#ReportModule', canActivate: [AuthGuard] },//lazy load report module and its components
  { path: 'losreport', component: LoSReportComponent },
  { path: 'internationalreport', component: InternationalReportComponent },
  { path: 'custompatientsreport', component: CustomPatientReportComponent },
  { path: '**', redirectTo: '/home'},//page not found route
 // { path: '', redirectTo: '/login', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule, HttpClientModule]
})

export class AppRoutingModule{
  constructor(public router: Router, private appService: AppService, private _dateTimeService: DateTimeFormatService) {
    this.router.events.subscribe(event => {
      //if (this.appService.UserProfile == undefined) {//moved to appService constructor
      //  this.appService.setUserProfile();
      //}
      if (event instanceof ActivationEnd) {
        if (event.snapshot.routeConfig.path)
          if (!event.snapshot.routeConfig.path.includes('run') && !event.snapshot.routeConfig.path.includes('patient')) {
            this.appService.Run = null;
            this.appService.Patient = null;
            this.appService.AllDateValidations = null;
            this.appService.MinMaxHardSoftValues = null;
            this.appService.RunCanBeEdited = false;
          }
      }
    });
  }
}


