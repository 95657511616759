import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
      const clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token')),
      });
      return next.handle(clonedReq).pipe(
        tap(
          succ => { },
          err => {
            if (err.status == 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('cultureSettings');
              localStorage.removeItem('unitSystem');
              localStorage.removeItem('cultureOptionSelected');
              localStorage.removeItem('centerName');
              localStorage.removeItem('centerId');

              this.router.navigateByUrl('/login');
              //console.log(err);
              //print error (works for each section)
              if (err.error) {
                //console.log(err);
                if (err.error.message) {
                  this.toastr.error(err.error.message);
                } else {
                  this.toastr.error(err.error);
                }
              } else {
                this.toastr.error('Session expired');
              }
            }
            if (err.status == 403) {
              this.toastr.error('Action not allowed. Your user role is not valid.');

              this.router.navigateByUrl('/');
            }
          }
        )
      );
    } else {
      if (this.router.url != '/sloginE0E52F31B39847C3A8C') {
        this.router.navigateByUrl('/login');//test 10-23-2023
      }

      return next.handle(req.clone());
    }
  }

}
